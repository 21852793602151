<template>
  <div
    class="navbar-container d-flex content align-items-center py-1"
    style="padding-bottom:0.6rem;"
  >

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1">
      <div class="d-flex">
        <h1
          :class="`text-dark font-weight-bolder`"
          style="padding-top:0.2rem;color:black !important;"
        >
          <span class="">
            <div
              v-if="loading"
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div
              v-if="!loading"
              class="d-flex align-items-center"
            >
              <b-img
                :src="business.company_logo ? `${bucketUrl}${business.company_logo}` : defaultCompanyLogo"
                alt="logo"
                class="brand-logo mr-2"
              />
              <div>
                {{ business.company_name }}
              </div>
            </div>
          </span>
        </h1>
      </div>
    </div>

    <!-- Right Col -->
    <b-navbar-nav
      class="nav align-items-center ml-auto"
      style="padding-top:0.3rem;"
    >
      <a
        href="/"
        style="margin-bottom:0.18rem;"
      >
        <feather-icon
          icon="HomeIcon"
          size="41"
          color="black"
        />
      </a>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BImg,
  BNavbarNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const trackingRepository = RepositoryFactory.get('tracking')

export default {
  directives: {
    Ripple,
  },
  components: {
    BImg,
    BNavbarNav,
  },
  mixins: [ErrorMessage],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      bucketUrl,
      defaultCompanyLogo,
      business: {},
      loading: false,
    }
  },
  mounted() {
    this.getBusinessInfo()
  },
  methods: {
    async getBusinessInfo() {
      try {
        this.loading = true
        const response = await trackingRepository.getBusinessInfo()
        if (response.status === 200) {
          this.business = response.data.data
          localStorage.setItem('businessSettings', JSON.stringify(this.business))
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>
.navbar-container {
  backdrop-filter: blur(5px);
  background: linear-gradient(90deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.1) 60%, rgba(255,255,255,0.07) 100%);
}
.brand-logo {
  width: 3.3rem;
  height: 3.3rem;
  border-radius: 0.3rem;
}
</style>
